<template>
  <v-layout>
    <v-flex xs3 sm2 md1 px-0 mx-0>
      <v-card :class="{'fixed-large': $vuetify.breakpoint.lgAndUp, 'fixed-small': $vuetify.breakpoint.mdAndDown, 'fixed-xs': $vuetify.breakpoint.xs}">
        <v-list dense class="py-1 grey darken-4" two-line router :to="item.link" v-for="(item, index) in items" :key="index">
          <v-list-tile router :to="item.link" exact>
            <v-list-tile-content>
              <v-list-tile-action class="justify-space-between">
                <v-icon class="justify-space-between" right>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-title class="text-xs-center pb-4">{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>

    <v-container grid-list-md pt-0 mt-0 px-0 ml-2>
      <!-- xs9 sm10 md5 lg3 -->
      <v-layout row wrap fluid pb-4 mb-4>
        <v-flex v-for="day in days" :key="day.id" pb-3 mb-3 xs12 md6 lg4>
          <v-card pb-4 mb-4 :class="{'mx-auto': $vuetify.breakpoint.lgAndUp}" max-width="400">
            <v-card dark flat>
              <v-img
                height="220"
                src="/assets/timeline.jpg"
                gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
              >
                <v-container>
                  <v-layout align-center>
                    <strong class="display-4 font-weight-regular mr-4">{{ day.date }}</strong>
                    <v-layout column justify-end>
                      <div class="headline font-weight-light">{{ day.name }}</div>
                      <div class="text-uppercase font-weight-light">{{ day.month }}</div>
                    </v-layout>
                  </v-layout>
                </v-container>
              </v-img>
            </v-card>
            <v-card-text>
              <v-timeline align-top dense v-for="(event, index) in day.events" :key="index">
                <v-timeline-item color="primary" small>
                  <v-layout column fill-height justify-end>
                    <v-flex>
                      <v-flex xs10 pb-0 mb-0>
                        <div class="caption">
                          <strong>{{ event.time }}</strong>
                        </div>
                        <strong>{{ event.name }}</strong>                        
                      </v-flex>
                    <v-chip  text-color="white" color="primary" v-if="event.included==true">
                      <v-avatar>
                      <v-icon size="16" >mdi-ticket</v-icon>
                      </v-avatar>
                      Included in Wristband                      
                    </v-chip>
                    <v-chip  text-color="white" color="primary" v-if="event.separate==true">
                      <v-avatar>
                      <v-icon size="16" >mdi-ticket-outline</v-icon>
                      </v-avatar>
                      Separate Ticket Required                      
                    </v-chip>
                    <v-chip  text-color="white" color="primary" v-if="event.lws==true">
                      <v-avatar>
                      <v-icon size="16" >mdi-account-group</v-icon>
                      </v-avatar>
                      Leather Weekend Supporter
                    </v-chip>
                    </v-flex>                    
                    <v-flex
                      class="caption grey--text text-xs-justify"
                      pt-0
                      mt-0
                    >{{ event.description }}</v-flex>
                    <v-flex>
                      <v-btn
                        block
                        :href="event.map"
                        target="_blank"
                        rel="noreferrer"
                        class="primary grey--text text--darken-4 mt-3 mb-4 py-1 position:absolute;"
                      >
                        <v-icon left>mdi-map-marker</v-icon>
                        {{ event.loc }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>
<style>
.v-list__tile__content {
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}

.fixed-xs {
  position: fixed;
  left: 0px;
  z-index: 1;
  opacity: 0.75;
}

.fixed-small {
  position: fixed;
  left: 0px;
  z-index: 1;
}

.fixed-large {
  position: fixed;
  z-index: 1;
}

</style>
<script>
export default {
  metaInfo: {
    titleTemplate: "%s | Leather Weekend",
    link: [{ rel: "canonical", href: "https://www.manchesterleathermen.com/weekend/events" }],
      meta: [
        { itemprop: "name", content: "MLM Weekend ← Manchester Leathermen " },
        { itemprop: "description", content: "Manchester Leather Weekend is back bigger and better than ever before." }
      ]
  },
  data: () => ({
    items: [
      { title: "Info", icon: "mdi-information-outline", link: "/weekend" },
      {
        title: "Events",
        icon: "mdi-calendar-star",
        link: "/weekend/events"
      },
      { title: "Tickets", icon: "mdi-ticket-account", link: "/weekend/tickets" }
    ],
    month: "October 2023",
    days: [
    {
        id: 0,
        name: "Thursday",
        month: "October 2024",
        src: "/assets/timeline1.jpg",
        date: "3",
        events: [
          {
            time: "19:00 to 21:00",
            name: "Early Registration",
            description:
              "Come say hello and pick up your weekend wristbands!",
            loc: "Eagle Lodge",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "19:00 to 21:00",
            name: "Pre-MLW Drinks",
            description:
              "Pre drinks at The Lodge, casual meet before the official start of the Manchester Leather Weekend.",
            loc: "Eagle Lodge",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 19:00",
            name: "Let's Get Quizzical",
            lws: true,
            description:
              `Join the legendary Lola Lasagne and DJ Daddy every Thursday for a fun-filled evening and the chance to win big! Lola’s big red boxes could either bring you our max jackpot, half the jackpot, your point in pounds, your team’s entry fee back (£3 per person), or a bottle of Eagle Poppers! The Jackpot rolls over if it isn’t chosen too, so the jackpot can get huge! Reserve your team at eaglemanchester.com.`,
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          }         
        ]
      },
      {
        id: 1,
        name: "Friday",
        month: "October 2024",
        src: "/assets/timeline1.jpg",
        date: "4",
        events: [
          {
            time: "From 16:00 to 22:00",
            name: "Registration",
            description:
              "Come say hello and pick up your weekend wristbands!",
            loc: "Eagle Lodge",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 19:00",
            name: "Welcome Drinks and Meet Up",
            description:
              "Join us for the Manchester Leather Social upstairs at The Eagle for our MLW edition social. Our bootblacks will be available to help your leathers look the best for the weekend!",
            loc: "Eagle Lodge",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 19:00",
            name: "Leather Dinner",
            separate: true,
            description:
              "Join us for the official leather weekend dinner.",
            loc: "DoubleTree by Hilton",
            map: "https://maps.app.goo.gl/DnL74bfXvvYa37JPA"
          },         
          {
            time: "From 21:00",
            name: "DJ Poni Friday",
            lws: true,
            description:
              "DJ Poni will be at REM playing you the tunes to kickstart your weekend.",
            loc: "REM Bar",
            map: "https://maps.app.goo.gl/BqQZmjFYQNZeL8hF7"
          },
          {
            time: "From 23:00",
            name: "DILF Party",
            included: true,
            description:
              "DILF is back and is having a special Leather edition of their famous DILF night.",
            loc: "Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },             
        ]
      },
      {
        id: 2,
        name: "Saturday",
        month: "October 2024",
        src: "/assets/timeline1.jpg",
        date: "5",
        events: [
          {
            time: "From 10:30",
            name: "Manchester Walking Tour",
            description:
              "The walking tours of MLW are back with your leather tour guides Neil & George. Join us to learn about the city of Manchester!",
            loc: "Sackville Gardens",
            map: "https://maps.app.goo.gl/8v5oZaNVkkYsa64n7"
          },
          {
            time: "12:00 to 14:00",
            name: "Movie Screening",
            description:
              "We’re showing two amazing short movies during the weekend, showcasing the iconic London leather club (movie 1), but also showing how a diverse array of lives intersect in a night at Manchester’s iconic Canal Street (movie  2). Movies: “The Backstreet” (2024, Romaine Beck) and “Spectrum” (2019, Kieron Moore).",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "14:00 to 16:45",
            name: "Sister Leathers Bingo",
            description:
              "The Manchester Sisters of Perpetual Indulgence are hosting a fun leather music bingo with some amazing prizes.",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },          
          {
            time: "15:00 to 17:00",
            name: "Cigar Social",
            description:
              "Looking for an opportunity to have a cigar and drink? We have you covered with our MLW CIGAR social!",
            loc: "The Thompsons Arms",
            map: "https://maps.app.goo.gl/bdHx7V3XNDbBGHM77"
          },
          {
            time: "18:00 to 23:00",
            name: "Collared",
            lws: true,
            description:
              "Collared is an all-encompassing fetish event on the first Saturday of every month. Whether you come to get tied up, tortured, tickled, tummy rubs, or talk with friends, Collared has you covered.",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 19:00",
            name: "The Leather Social with BLUF",
            description:
              "Join us for the BLUF and Leather Social upstairs at The Eagle. Our bootblacks will be available to help your leathers look the best for the weekend!",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 20:00",
            name: "Shoot Your Shot",
            lws: true,
            description:
              "If you love the 80's, you will love Shoot your Shot every Saturday at REM.",
            loc: "The REM Bar",
            map: "https://maps.app.goo.gl/BqQZmjFYQNZeL8hF7"
          },
          {
            time: "21:00 to 03:00",
            name: "GRUNT",
            included: true,
            description:
              "This year we have a special leather night lined up for you with sexy leather décor and BDSM vibes. Join us for some sexy music, darkrooms for playing and cruising in a dark basement full of sexy men in leather.",
            loc: "Sub101",
            map: "https://maps.app.goo.gl/xsYdxkVZ8WrFhfDZ8"
          },
          {
            time: "From 23:00",
            name: "DEVIANT - MLW Edition",
            included: true,
            description:
              "The sexiest night at The Eagle Manchester, Deviant is a dark and sexy night made just for the Rubber and Leather lovers in the scene. Dark corners where you can play and cruise, cold beers, and banging tunes will help you get in the mood to have some fun with your fellow fetish men.",
           loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          }
        ]
      },
      {
        id: 3,
        name: "Sunday",
        month: "October 2024",
        src: "/assets/timeline2.jpg",
        date: "6",
        events: [
          {
            time: "11:00 to 12:30",
            name: "MLM brunch",
            separate: true,
            description:
              "Join us for some food while you recharge in a relaxed atmosphere.",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "13:00",
            name: "Art & Sober social",
            description:
              "Homme de Cuir (Do it For Daddy) is exhibiting his sexy new work for us during MLW. If you like sexy art and want a chill social during the day, join us at VIA.",
            loc: "VIA",
            map: "https://maps.app.goo.gl/4c5iNmEVz8U21HTe8"
          },
          {
            time: "14:00 to 16:00",
            name: "MLM Pop Up Photography",
            description:
              "This year we have set up a photography booth for you to have your professional picture taken (and take home a small MLW souvenir)!",
            loc: "VIA",
            map: "https://maps.app.goo.gl/4c5iNmEVz8U21HTe8"
          },
          
          {
            time: "14:00 to 18:30",
            name: "Sunday Service",
            lws: true,
            description:
              "Every Sunday Afternoon, we have some of the biggest and best drag acts in the UK take to our stage, and join the amazing Gladys Duffy in providing an afternoon of top tier entertainment. Icons such as Divina De Campo, Danny Beard and Myra DuBois have all taken their turns on this iconic stage, so you are guaranteed a good time at Sunday Service!",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 14:00",
            name: "Afternoon Drinks",
            description:
              "Join us at The REM Bar on Canal street for some drinks and laughs.",
            loc: "The REM Bar",
            map: "https://maps.app.goo.gl/BqQZmjFYQNZeL8hF7"
          },
          {
            time: "From 15:00",
            name: "Manchester Gay History Walking Tour",
            description:
              "TheThe walking tours of MLW are back with your leather tour guides Neil & George. Join us to learn about the city of Manchester!",
            loc: "Sackville Gardens",
            map: "https://maps.app.goo.gl/8v5oZaNVkkYsa64n7"
          },
          {
            time: "From 20:00",
            name: "Sing On Sunday",
            lws: true,
            description:
              "Your Hostess Vivienne Lynsey invites you to defy gravity and join them in a mass musical theatre sing-a-long every Sunday evening!",
            loc: "The Cockatoo Club",
            map: "https://maps.app.goo.gl/3pHMKEsX2syK7ayb9"
          },
          {
            time: "From 20:00",
            name: "Beareoke",
            lws: true,
            description:
              "Fun, frolics, and a flirtatious DJ are just some of the things you can expect from Beareoke. If you love Karaoke (either taking part, or just watching), Beareoke is the friendliest, and most supportive Karaoke you’re going to find!",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 20:00",
            name: "Farewell drinks",
            description:
              "The weekend is coming to an end and we are meeting at the lodge to say our goodbyes!",
            loc: "The Eagle Lodge",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
          {
            time: "From 21:00",
            name: "Leather Disco",
            included: true,
            description:
              "The MLW leather disco is back again. Cheesy disco music and sexy men in leather to end the weekend.",
            loc: "The Eagle",
            map: "https://goo.gl/maps/zt42tvYMYHWXirQSA"
          },
        ]
      },
      {
        id: 0,
        name: "Monday",
        month: "October 2024",
        src: "/assets/timeline1.jpg",
        date: "7",
        events: [
          {
            time: "10:00 to 12:00",
            name: "Tea & Cake",
            description:
              "Tea and cake in Manchester’s award winning ‘Alice in Wonderland’ themed dining experience is complemented with freshly prepared sandwiches, cakes and treats – all individually made in house!",
            loc: "Richmond Tea Rooms",
            map: "https://maps.app.goo.gl/jbYkbPtW62QnEqRN9"
          }         
        ]
      },
    ]
  })
};
</script>
